import React, { useState } from 'react';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../firebase';

import {
  Typography,
  TextField,
  Button,
  Container,
  Paper,
  IconButton
} from '@mui/material';

import {
  Visibility,
  VisibilityOff,
  AlternateEmail,
} from '@mui/icons-material';

import GoogleIcon from '@mui/icons-material/Google';

import { useNavigate } from 'react-router-dom';

import Logo from '../logo.png';

const Login = () => {
  
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async () => {
    try {
      if (!email || !password) {
        setLoginError('Please enter both email and password.');
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setLoginError('Please enter a valid email address.');
        return;
      }

      await signInWithEmailAndPassword(auth, email, password);
      setLoginError(null);
      navigate('/');
    } catch (error) {
      console.error(error.message);
      setLoginError('Invalid email or password');
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      setLoginError(null);
      navigate('/');
    } catch (error) {
      console.error(error.message);
      setLoginError('Google login failed');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const buttonStyle = { textTransform: 'capitalize' };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ ...containerStyle, mt: 8 }}>
      <Paper elevation={3} sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src={Logo} alt="Logo" style={{ width: '80px', marginBottom: '16px' }} />
        <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
          AI Artistry
        </Typography>
        {loginError && (
          <Typography color="error" sx={{ mb: 2 }}>
            {loginError}
          </Typography>
        )}
        <form noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email Address"
            type="email"
            value={email}
            autoComplete="username"
            onChange={(e) => setEmail(e.target.value)}
            error={loginError && !email}
            helperText={loginError && !email && 'Email is required and must be valid'}
            sx={{ mb: 2 }}
            onKeyDown={handleKeyDown}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
            error={loginError && !password}
            helperText={loginError && !password && 'Password is required'}
            sx={{ mb: 2 }}
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleTogglePassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleLogin}
            disabled={!email || !password}
            sx={{ mb: 1, ...buttonStyle }}
            startIcon={<AlternateEmail />}
          >
            Login
          </Button>
          <Button
            type="button"
            fullWidth
            variant="outlined"
            color="primary"
            onClick={handleGoogleLogin}
            sx={{ mb: 1, ...buttonStyle }}
            startIcon={<GoogleIcon />}
          >
            Login with Google
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
