import React, { useState } from 'react';
import { Button, Typography, Container, Paper, Box } from '@mui/material';
import { auth, db } from '../firebase';
import { collection, query, where, getDoc, getDocs, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { useAuth } from './AuthContext';
import DeleteIcon from '@mui/icons-material/Delete';
import LogoutIcon from '@mui/icons-material/Logout';

const Main = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleDeleteData = async () => {
    setError(null);
    setLoading(true);

    try {
      if (currentUser) {
        const oldPath = `artists/${currentUser.uid}`;
        const newPath = `removedArtists/${currentUser.uid}`;

        const oldPathDoc = doc(db, oldPath);
        const oldPathSnapshot = await getDoc(oldPathDoc);

        if (oldPathSnapshot.exists()) {
          const data = oldPathSnapshot.data();
          const newPathDoc = doc(db, newPath);

          await setDoc(newPathDoc, data);

          const artsRef = collection(db, 'art');
          const q = query(artsRef, where('creatorId', '==', currentUser.uid));
          const querySnapshot = await getDocs(q);

          querySnapshot.forEach(async (d) => {
            const data = d.data();
            const removedArtsRef = doc(db, `removedArts/${d.id}`);
            await setDoc(removedArtsRef, data);
            await deleteDoc(d.ref);
          });

          await deleteDoc(oldPathDoc);
          console.log('Data deleted successfully!');
        } else {
          console.log('No data found to delete.');
        }

        await auth.currentUser.delete();

        auth.signOut();

        console.log('Account deleted successfully!');
      } else {
        setError('User not logged in.');
      }
    } catch (error) {
      setError('Error deleting data: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    if (currentUser) {
      auth.signOut();
    }
  };

  const warningMessageStyle = {
    backgroundColor: '#ffee58',
    padding: '16px',
    marginBottom: '16px',
    borderRadius: '4px',
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Box textAlign="center" mb={2}>
          <Typography variant="h6" gutterBottom>
            Welcome<br />{currentUser.email}
          </Typography>
        </Box>
        <div style={warningMessageStyle}>
          <Typography variant="body1" align="center">
            <strong>WARNING:</strong> Clicking the button below will result in the permanent deletion of the following items:
          </Typography>
          <ul>
            <li>Your profile</li>
            <li>Your credits</li>
            <li>Any generated images</li>
            <li>Your account itself</li>
          </ul>
          <Typography variant="body1" align="center">
            This action cannot be undone.
          </Typography>
        </div>
        <Button
          variant="contained"
          color="error"
          fullWidth
          startIcon={<DeleteIcon />}
          onClick={handleDeleteData}
          disabled={loading}
          style={{ marginBottom: '10px', textTransform: 'capitalize' }}
        >
          {loading ? 'Deleting...' : 'Delete Data'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<LogoutIcon />}
          onClick={handleLogout}
          disabled={loading}
          style={{ textTransform: 'capitalize' }}
        >
          Logout
        </Button>
        {error && (
          <Typography variant="body1" align="center" color="error" style={{ marginTop: '10px' }}>
            {error}
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default Main;
