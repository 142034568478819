import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Main from './components/Main';
import { Container, CssBaseline } from '@mui/material';
import { useAuth } from './components/AuthContext';

const App = () => {

  const { currentUser } = useAuth();

  return (
    <Router>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Routes>
          <Route path="/login" element={currentUser ? <Navigate to="/" /> : <Login />} />
          <Route path="/" element={currentUser ? <Main /> : <Navigate to="/login" />} />
        </Routes>
      </Container>
    </Router>
  );
};

export default App;
