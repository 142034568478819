import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCnR7gtqUw4k8mdBQGzJ4Y707-JIUUdcZM",
    authDomain: "aiartistry.firebaseapp.com",
    projectId: "aiartistry",
    storageBucket: "aiartistry.appspot.com",
    messagingSenderId: "186405678611",
    appId: "1:186405678611:web:841b343718ebbefbaad9e6",
    measurementId: "G-F379SPZQZ7"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
